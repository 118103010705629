<template>
  <div>
    <van-action-sheet v-model="showAction" :title="'发货'" class="modalclass">
      <div class="combox">
        <div class="userinfo">
          <div class="name"   @click="copyLink1(sendinfo.consignee)" id="tag1">
            <img
              class="icon"
              :src="require('@/assets/images/mall/shop/usericon.png')"
            />
            <div class="txt">{{ sendinfo.consignee }}</div>
            <div style="color: #00f;margin-left: 8px;font-size: 0.32rem;font-weight: 400;font-family: 'PingFang SC';">复制</div>
          </div>
          <div class="name marginT8"   @click="copyLink2(sendinfo.mobile)" id="tag2">
            <img
              class="icon"
              :src="require('@/assets/images/mall/shop/telicon.png')"
            />
            <div class="txt">{{ sendinfo.mobile }}</div>
            <div style="color: #00f;margin-left: 8px;font-size: 0.32rem;font-weight: 400;font-family: 'PingFang SC';">复制</div>
          </div>
          <div class="name marginT8"   @click="copyLink3(sendinfo)" id="tag3">
            <img
              class="icon"
              :src="require('@/assets/images/mall/shop/locationicon.png')"
            />
            <div class="txt">
              {{sendinfo.province_str}} {{sendinfo.city_str}} {{sendinfo.district_str}} {{ sendinfo.area }}
            </div>
            <div style="color: #00f;margin-left:8px;font-size: 0.32rem;font-weight: 400;font-family: 'PingFang SC';">复制</div>
          </div>
        </div>
        <div class="expressage">
          <div class="inputbox">
            <van-field
                    label-align="right"
                    label-width="60"
                    v-model="express"
                    label="物流名称"
                    placeholder="请输入物流名称"
            />
           <!-- <van-field
              @click="selectexpress"
              label-align="right"
              label-width="60"
              readonly
              right-icon="arrow-down"
              v-model="express"
              label="选择物流"
              placeholder="请选择物流名称"
            />-->
          </div>
          <div class="inputbox marginT16">
            <van-field
              label-align="right"
              label-width="60"
              v-model="shippcode"
              label="快递单号"
              placeholder="请输入快递单号"
            />
          </div>
        </div>
        <div class="btncontent">
          <div class="btn" @click="clickStore">确定</div>
        </div>
      </div>
    </van-action-sheet>
    <van-popup v-model="showPicker" round position="top">
      <van-picker
        show-toolbar
        :columns="expresscompany"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      >
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { deliverOrder } from "@/request/storeapi";
import Clipboard from 'clipboard'

export default {
  data() {
    return {
      showPicker: false,
      expresscompany: ["顺丰", "圆通", "韵达"],
      showAction: false,
      sendinfo: {},
      express: "",
      shippcode: "",
    };
  },
  methods: {
      //复制
      copyLink1(content){
          var _this = this;
          let clipboard = new Clipboard('#tag1', {
              text: function () {
                  return content;
              }
          })
          clipboard.on('success', e => {
              Toast('复制成功');
              // 释放内存
              clipboard.destroy()
          })
          clipboard.on('error', e => {
              Toast('复制失败');
              clipboard.destroy()
          })
      },
      copyLink2(content){
          var _this = this;
          let clipboard = new Clipboard('#tag2', {
              text: function () {
                  return content;
              }
          })
          clipboard.on('success', e => {
              Toast('复制成功');
              // 释放内存
              clipboard.destroy()
          })
          clipboard.on('error', e => {
              Toast('复制失败');
              clipboard.destroy()
          })
      },
      copyLink3(content){
          var _this = this;
          let clipboard = new Clipboard('#tag3', {
              text: function () {
                  return content.province_str+content.city_str+content.district_str+content.area;
              }
          })
          clipboard.on('success', e => {
              Toast('复制成功');
              // 释放内存
              clipboard.destroy()
          })
          clipboard.on('error', e => {
              Toast('复制失败');
              clipboard.destroy()
          })
      },
    requestdeliverOrder() {
      const params = {
        order_id: this.sendinfo.order_id,
        shipping_code: this.shippcode,
        shipping_name: this.express,
      };
      deliverOrder(params).then((res) => {
        let success = res.success;
        if (success) {
          this.$emit("refreshorder");
          this.showAction = false;
        }
        Toast(res.msg);
      });
    },
    preview(item) {
      this.showAction = true;
      this.sendinfo = item;
      console.log(this.sendinfo);
    },
    onConfirm(value) {
      this.showPicker = false;
      this.express = value;
    },
    selectexpress() {
      this.showPicker = true;
    },
    clickStore() {
      if (this.express.length === 0) {
        Toast("请选择物流名称");
        return;
      }
      if (this.shippcode.length === 0) {
        Toast("请输入快递单号");
        return;
      }
      this.requestdeliverOrder();
    },
  },
};
</script>

<style lang="scss" scoped>
.marginT8 {
  margin-top: 8px;
}
.marginT16 {
  margin-top: 16px;
}
.modalclass {
  border-radius: 12px 0px;
  background: white;
  .combox {
    padding: 32px 32px 50px;
    .userinfo {
      .name {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
      .txt {
        color: rgba(105, 98, 95, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
      }
    }
    .expressage {
      margin-top: 16px;
      .inputbox {
        width: 686px;
        height: 84px;
        border-radius: 16px;
        opacity: 1;
        border: 2px solid rgba(255, 223, 204, 1);
        background: rgba(250, 247, 245, 1);
        .verode {
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          color: rgba(255, 114, 32, 1);
        }
        ::v-deep .van-cell {
          background-color: transparent;
          line-height: 20px;
        }
        ::v-deep .van-cell__title {
          font-weight: 700;
          font-family: "PingFang SC";
          color: #000000;
        }
      }
    }
    .btncontent {
      display: flex;
      justify-content: center;
      margin-top: 74px;
      .btn {
        width: 478px;
        height: 72px;
        border-radius: 74px;
        opacity: 1;
        background: rgba(255, 114, 32, 1);
        color: rgba(238, 238, 238, 1);
        font-size: 28px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 72px;
      }
    }
  }
}
</style>