<template>
  <div class="shopcontent">
    <div class="header">
      <div class="headercontent">
        <div class="left">
          <img @click="clickBack" class="backicon" :src="require('@/assets/images/mall/goods/shopback.png')" />
          <div class="headertitle">商家后台</div>
        </div>
        <div class="right">
          <img
            @click="clickstop"
            class="stopimg marginR16px"
            :src="require('@/assets/images/mall/goods/stopicon.png')"
          />
          <img @click="clickshopset" class="stopimg" :src="require('@/assets/images/mall/goods/seticon.png')" />
        </div>
      </div>
      <div class="sellbox">
        <div class="sellnum" v-for="(count, index) in countarr" :key="index">
          <div class="num">{{ count.num }}</div>
          <div class="txt">{{ count.name }}</div>
        </div>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="shopInfo">
        <div class="statistical">
          <div class="profitbox" @click="clickstatistical">
            <div class="timenow">{{ currentdate }}</div>
            <div class="profitnum">收益总数：{{ statisticsinfo.totalProfit }}</div>
          </div>
          <div id="chart" class="linechart"></div>
        </div>
        <div class="ordercontent">
          <van-tabs v-model="tabvalue" @change="changeTab">
            <div v-for="tab in orderstatus" :key="tab">
              <van-tab :title="tab">
                <!-- <div class="paytime">
                  <div class="counttxt">支付倒计时：</div>
                  <div class="countdown">
                    <van-count-down format="mm:ss" :time="counttime" />
                  </div>
                </div> -->

                <div class="celllist">
                  <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div v-for="(item, index) in orderlist" :key="index" :title="item" class="ordercell">
                      <div class="celltop">
                        <div class="time">{{ item.add_time }}</div>
                        <div class="status">
                          {{ orderstatusTtx(item.order_status) }}
                        </div>
                      </div>
                      <div class="goodinfo"   @click="clickpaypage(item)">
                        <img class="goodimg" :src="item.images" />
                        <div class="info">
                          <div class="des">
                            {{ item.name }}
                          </div>
                          <div class="price">价值 {{ parseFloat(item.goods_price).toFixed(2) }}USD</div>
                        </div>
                      </div>
                      <div class="cellbottom">
                        <div class="bottom">
                          <span class="paytip">支付</span>
                          <span class="paynum">{{ parseFloat(item.pay_amount).toFixed(2) }}</span>
                          <span class="payunit">/{{ item.pay_name }}</span>
                        </div>

                        <div v-if="item.order_status === 1" @click="clicksend(item)" class="statusbtn">
                          发货
                        </div>
                        <div
                          @click="clickviewApply(item)"
                          class="statusbtn"
                          v-if="item.aftersale_status === 1 || aftersale_status === 2 ||  item.order_status === 3"
                        >
                          查看申诉
                        </div>
                      </div>
                    </div>
                  </van-list>
                </div>
              </van-tab>
            </div>
          </van-tabs>
        </div>
      </div>
    </van-pull-refresh>
    <div class="bottomview">
      <div class="warehouse" @click="clickWarehouse">商品仓库</div>
      <div class="publish" @click="clickpublish">发布商品</div>
    </div>
    <delivery ref="delivery" @refreshorder="refreshorder"></delivery>
    <viewapply ref="viewapply"></viewapply>
  </div>
</template>

<script>
import { indexStatistics, orderList, exitStore } from "@/request/storeapi";
import { Dialog, Toast } from "vant";
import Vue from "vue";
import Delivery from "./actionsheet/delivery.vue";
import viewapply from "./actionsheet/viewapply.vue";
import * as echarts from "echarts";
export default {
  components: { Delivery, viewapply },
  data() {
    return {
      statisticsinfo: {
        totalProfit: "",
      },
      countarr: [],
      orderlist: [],
      loading: false,
      finished: false,
      refreshing: false,
      tabvalue: 0,
      pagename: "我的订单",
      orderstatus: ["待支付", "待发货", "已发货", "已完成"],
      counttime: 60 * 60 * 1000,
      currentpage: 1,
      currentdate: "",
      profitList: [],
      myChart: Object,
        password: "",
        showpwd:false,
        tmpItem:{},
        clickName:'',
    };
  },
  mounted() {},
  activated() {
    let year = new Date().getFullYear();
    //月份是从0月开始获取的，所以要+1;
    let month = new Date().getMonth() + 1;
    //日
    let day = new Date().getDate();
    this.currentdate = year + "-" + month + "-" + day;
    this.requestStatistics();
    this.onRefresh();
  },
  methods: {
      clickpaypage(item) {
          this.$router.push({
              path: "/mall/paypage",
              query: {
                  id: item.order_id,
              },
          });
      },
    drawchart() {
      this.myChart = echarts.init(document.getElementById("chart"), null, {});
      let xArr = this.profitList
        .map((obj, index) => {
          return obj.createTime;
        })
        .join(",")
        .split(",");
      let valuearr = this.profitList
        .map((obj, index) => {
          return parseFloat(obj.total).toFixed(2);
        })
        .join(",")
        .split(",");
      let option = {
        grid: {
          height: "80%",
          y: "10%",
        },
        xAxis: {
          type: "category",
          data: xArr,
        },
        yAxis: {
          type: "value",
        },

        series: [
          {
            data: valuearr,
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#FF7220",
            },
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "black",
                // 拐点上显示数值
                borderColor: "#FF7220", // 拐点边框颜色
              },
            },
          },
        ],
      };
      this.myChart.setOption(option);
    },
    refreshorder() {
      this.onRefresh();
    },
    changeTab(value) {
      this.onRefresh();
    },
    orderstatusTtx(status) {
      let txt = "";
      if (status === 0) {
        txt = "待支付";
      } else if (status == 1) {
        txt = "待发货";
      } else if (status == 2) {
        txt = "已发货";
      } else if (status == 3) {
        txt = "已完成";
      }
      return txt;
    },
    requestOrderList() {
      const params = {
        page: this.currentpage,
        limit: 20,
        order_status: this.tabvalue,
      };
      orderList(params).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
        }
          this.orderlist = [ ...res.data.list];
        this.loading = false;
        if (res.data.list.length < 20) {
          this.finished = true;
        }
      });
    },
    requestStatistics() {
      indexStatistics().then((res) => {
        console.log(res);
        this.statisticsinfo = res.data;
        this.countarr = [];
        this.profitList = this.statisticsinfo.profitList;
        this.dealstatisticdata();
        this.drawchart();
      });
    },
    dealstatisticdata() {
      this.countarr.push({
        name: "上架",
        num: this.statisticsinfo.goodMarketable_count,
      });
      this.countarr.push({
        name: "下架",
        num: this.statisticsinfo.goodUnMarketable_count,
      });
      this.countarr.push({
        name: "库存不足",
        num: this.statisticsinfo.goodStock_count,
      });
      this.countarr.push({
        name: "待审核",
        num: this.statisticsinfo.goodCheck_count,
      });
      this.countarr.push({
        name: "待支付",
        num: this.statisticsinfo.orderPay_count,
      });
      this.countarr.push({
        name: "待发货",
        num: this.statisticsinfo.orderDeliver_count,
      });
      this.countarr.push({
        name: "待收货",
        num: this.statisticsinfo.orderHarvest_count,
      });
      this.countarr.push({
        name: "已完成",
        num: this.statisticsinfo.orderFinsh_count,
      });
    },
    clickWarehouse() {
      this.$router.push({
        path: "/mall/warehouse",
      });
    },
    clickpublish() {
      this.$router.push({
        path: "/mall/publishgood",
      });
    },
    clickstatistical() {
      this.$router.push({
        path: "/mall/earnings",
      });
    },
    clicksend(item) {
      this.$refs.delivery.preview(item);
    },
    clickBack() {
      this.$router.go(-1);
    },
    clickstop() {
      Dialog.confirm({
        title: "解除商家",
        message: "您确定解除商家，退回店铺押金？",
        confirmButtonText: "确认",
        confirmButtonColor: "#FF7220",
      })
        .then(() => {
          this.requestexitStore();
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    requestexitStore() {
      exitStore().then((res) => {
        Toast(res.msg);
        let success = res.success;
        if (success) {
          Vue.ls.remove("token");
          Vue.ls.remove("storeuserid");
          this.$router.push({
            path: "/home/index",
          });
        }
      });
    },
    clickshopset() {
      this.$router.push({
        path: "/mall/completedata",
      });
    },
    onLoad() {
      this.requestOrderList();
        // this.currentpage++;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.orderlist = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    clickviewApply(item) {
      this.$refs.viewapply.preview(item);
    },
  },
};
</script>

<style scoped lang="scss">
.marginR16px {
  margin-right: 16px;
}
.shopcontent {
  min-height: 100vh;
  background: rgba(235, 238, 242, 1);
  .shopInfo {
    padding: 0 24px;
    position: relative;
    .statistical {
      padding: 32px;
      width: 702px;
      height: 518px;
      border-radius: 0 0 24px 24px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      .profitbox {
        width: 638px;
        height: 68px;
        border-radius: 16px;
        opacity: 1;
        background: rgba(255, 247, 242, 1);
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .timenow {
          color: rgba(105, 98, 95, 1);
          font-size: 24px;
          font-weight: 500;
          font-family: "DIN";
          text-align: center;
          line-height: 68px;
        }
        .profitnum {
          color: rgba(156, 152, 150, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 36px;
        }
      }
      .linechart {
        height: 370px;
        width: 638px;
      }
    }
    .ordercontent {
      ::v-deep .van-tabs__nav {
        background-color: transparent;
      }
      ::v-deep .van-tabs__line {
        width: 28px;
        height: 4px;
        border-radius: 4px;
        opacity: 1;
        background: rgba(255, 114, 32, 1);
        bottom: 20px;
      }
      .paytime {
        margin-top: 24px;
        display: flex;
        padding: 8px 24px;
        width: 702px;
        height: 98px;
        border-radius: 24px 24px 0 0;
        opacity: 1;
        background: #faf8f6;
        .counttxt {
          width: 144px;
          height: 36px;
          opacity: 1;
          color: rgba(156, 152, 150, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 36px;
        }
        .countdown {
          ::v-deep .van-count-down {
            color: rgba(203, 52, 241, 1);
            font-size: 12px;
            font-weight: 500;
            font-family: "DIN";
          }
        }
      }
      .celllist {
        // position: relative;
        // top: -46px;
      }
      .ordercell {
        width: 702px;
        height: 280px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(255, 255, 255, 1);
        padding: 24px;
        margin-bottom: 24px;
        .celltop {
          display: flex;
          justify-content: space-between;
          .time {
            color: rgba(105, 98, 95, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 36px;
          }
          .status {
            width: 116px;
            height: 36px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(252, 242, 255, 1);
            color: rgba(203, 52, 241, 1);
            font-size: 20px;
            text-align: center;
            line-height: 36px;
          }
        }
        .goodinfo {
          margin-top: 16px;
          display: flex;
          .goodimg {
            width: 112px;
            height: 112px;
            background-color: #ffaf37;
            margin-right: 16px;
          }
          .info {
            .des {
              width: 526px;
              color: rgba(41, 33, 29, 1);
              font-size: 24px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: left;
            }
            .price {
              margin-top: 8px;
              color: rgba(105, 98, 95, 1);
              font-size: 24px;
              font-weight: 400;
              text-align: left;
            }
          }
        }
        .cellbottom {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          .bottom {
            font-size: 24px;
            font-family: "PingFang SC";
            line-height: 36px;
            .paytip {
              color: rgba(156, 152, 150, 1);
              font-size: 24px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: left;
              line-height: 36px;
              margin-right: 8px;
            }
            .paynum {
              color: rgba(241, 27, 27, 1);
              font-weight: 500;
              text-align: left;
              font-size: 32px;
              font-family: "DIN";
              line-height: 44px;
            }
            .payunit {
              color: rgba(241, 27, 27, 1);
              font-weight: 400;
              text-align: left;
              font-size: 24px;
              font-family: "PingFang SC";
              line-height: 36px;
            }
          }
          .statusbtn {
            width: 136px;
            height: 52px;
            border-radius: 50px;
            opacity: 1;
            background: rgba(255, 247, 242, 1);
            color: rgba(255, 114, 32, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 52px;
          }
        }
      }
    }
  }
  .bottomview {
    position: fixed;
    bottom: 0px;
    width: 750px;
    height: 112px;
    opacity: 1;
    background: rgba(250, 247, 245, 1);
    box-shadow: 0 -8px 8px 0 rgba(80, 29, 0, 0.12);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .warehouse {
      width: 332px;
      height: 72px;
      border-radius: 74px;
      opacity: 1;
      background: rgba(255, 223, 204, 1);
      color: rgba(255, 114, 32, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 72px;
    }
    .publish {
      width: 332px;
      height: 72px;
      border-radius: 74px;
      opacity: 1;
      background: rgba(255, 114, 32, 1);
      color: rgba(238, 238, 238, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 72px;
    }
  }
}
.header {
  width: 750px;
  height: 480px;
  opacity: 1;
  background: url("../../assets/images/mall/goods/shopbg.png");
  background-size: 100% 100%;
  .headercontent {
    padding: 88px 24px 0px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .backicon {
        width: 64px;
        height: 64px;
        margin-right: 16px;
      }
      .headertitle {
        color: white;
        font-size: 32px;
        font-weight: 700;
        font-family: "PingFang SC";
      }
    }
    .right {
      display: flex;
    }

    .stopimg {
      width: 64px;
      height: 64px;
    }
  }
  .sellbox {
    margin-top: 28px;
    width: 686px;
    height: 266px;
    border-radius: 24px;
    opacity: 1;
    background: rgba(255, 247, 242, 1);
    margin-left: 32px;
    padding: 24px 0px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    .sellnum {
      width: 170px;
      text-align: center;
      .num {
        color: rgba(41, 33, 29, 1);
        font-size: 28px;
        font-weight: 500;
        font-family: "DIN";
        text-align: center;
        line-height: 40px;
      }
      .txt {
        color: rgba(156, 152, 150, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 36px;
      }
    }
  }
}
</style>
